@font-face {
    font-family: Poppins;
    src: url("../../assets/poppins/Poppins-Medium.otf");
}

ul li {
    color: #454545;
    font-size: 16px;
    font-family: 'Nulshock';
}

.wallet-btn {
    background: #ad0f5b;
    box-shadow: 0px 1px 20px 1px rgba(32, 209, 124, 0.4);
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.switch-btn {
    background: rgba(157, 22, 77, 0.2);
    /*box-shadow: 0px 2px 40px 2px rgba(32, 209, 124, 0.3);*/
    border-radius: 10px;
    border: 2px solid #ad0f5b;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #D11963;
}

.connected-btn {
    background:  #1E1E1E;
    border-radius: 15px;
    padding: 10px 2rem;
    z-index: 20;
    position: absolute;
    top: 10px;
    right: 3rem;
}

.connected-btn-sm {
    /*background: linear-gradient(rgba(105, 105, 105, 0.16), rgba(105, 105, 105, 0.16), #1E1E1E);*/
    background:  #1E1E1E;
    border-radius: 15px;
    padding: 10px 2rem;
    z-index: 20;
    position: absolute;
    top: 3px;
}
.connect-btn-group{
    position: absolute;
    top: 10px;
}

.btn-cover{
    position: relative;
    width: 16.5rem;
}

.balance-tab {
    position: absolute;
    right: 30rem;
}

@media screen and (min-width: 1621px) {
    .connected-btn {
        right: 8rem;
    }
}

@media screen and (max-width: 1100px) {
    .wallet-btn {
        font-size: 14px;
    }
}

@media screen and (max-width: 640px) {
    .wallet-btn {
        font-size: 12px;
        border-radius: 8px;
    }

    .connected-btn {
        border-radius: 10px;
        padding: 7px 10px;
        right: 5px;
        z-index: 99;
    }
    .btn-cover{
        position: relative;
        width: 12rem;
    }
    .switch-btn{
        font-size: 12px;
    }
}

.small-nav {
    background-color: rgb(18, 9, 21);
}

.connected-text {
    font-size: 10px;
}

.green-dot {
    background-color: #08DF6B;
    width: 9px;
    height: 7px;
}

.nulshock-text {
    font-family: 'Nulshock';
}

.noti {
    box-shadow: 0px 2px 40px 2px rgba(32, 209, 124, 0.4);
    background-color: white;
}

.poppin-text {
    font-family: Poppins;
}