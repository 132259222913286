@font-face {
  font-family: 'Nulshock';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/nulshock.ttf");
}
.left-col {
  background:  linear-gradient(rgba(105, 105, 105, 0.16), rgba(105, 105, 105, 0.16), #1E1E1E);
  border-radius: 20px;
}
.right-col {
  background:  linear-gradient(rgba(105, 105, 105, 0.16), rgba(105, 105, 105, 0.16), #1E1E1E);
  /* background-img : url("./../../assets/bgRight.png") ; */
  border-radius: 20px;
}
.simple-text {
  color: rgba(123, 117, 117, 1);
}
.hr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.hr-2{
  border-bottom: 2px solid rgba(255, 255, 255, 0.16);
}
.sub-heading {
  color: rgba(43, 197, 222, 1);
}

.simple-text-2 {
  color: rgba(223, 223, 223, 1);
}

.status-btn {
  color: #faff00;
  background: rgba(186, 189, 8, 0.2);
  border: 2px solid #faff00;
  border-radius: 8px;
  font-weight: 700;
  font-size: 15px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.check-vesting-btn{
  color: #faff00;
  background: rgba(186, 189, 8, 0.2);
  border: 4px solid #faff00;
  border-radius: 8px;
  font-weight: 700;
  font-size: 21px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.time-block {
  background: #110516;
  border-radius: 11px;
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 1100px) {
  .right-col {
    min-height: 40rem;
  }
}

.live {
  color: #FF0000;
}

.live-btn {
  color: #ff0000;
  background: rgba(223, 2, 2, 0.2);
  border: 2px solid #ff0000;
  border-radius: 8px;
  font-weight: 700;
  font-size: 15px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.end-btn {
  color: #DB00FF;
  background: rgba(173, 4, 201, 0.2);
  border: 2px solid #DB00FF;
  border-radius: 8px;
  font-weight: 700;
  font-size: 15px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.days-text {
  color: #dfdfdf;
}
.amount-div {
  border: 2px solid #404042;
  border-radius: 20px;
}
.input {
  background-color: transparent;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}
.input:focus {
  background-color: transparent!important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.max-btn {
  color: #78a8f8;
  background: rgba(41, 100, 200, 0.22);
  border: 1px solid #3674dd;
  border-radius: 8px;
  font-weight: 700;
  font-size: 15px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.simple-text-3 {
  color: #aaaaaa;
}
.enter-btn {
  background: #41444e;
  box-shadow: 0px 2px 40px 2px rgba(65, 68, 78, 0.4);
  border-radius: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6d7282;
}

.insufficient-btn {
  color: #D11963;
  background: rgba(157,22,77,0.2);
  box-shadow: 0px 2px 40px 2px rgba(65, 68, 78, 0.4);
  border-radius: 20px;
  border: 2px solid #D11963;
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.min-btn {
  color: #3674DD;
  background: rgba(14,73,173,0.2);
  box-shadow: 0px 2px 40px 2px rgba(65, 68, 78, 0.4);
  border-radius: 20px;
  border: 2px solid #3674DD;
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remaining-btn{
  color: #FAFF00;
  background: rgba(186,189,8,0.2);
  box-shadow: 0px 2px 40px 2px rgba(65, 68, 78, 0.4);
  border-radius: 20px;
  border: 2px solid #FAFF00;
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-remaining-btn{
  color: #6D7282;
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.max-remaining-btn button{
  color: #3674DD;
}
.approve-btn{
  color: #fff;
  background: #D11963;
  box-shadow: 0px 2px 40px 2px rgba(65, 68, 78, 0.4);
  border-radius: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.approve-btn.hidden{
  display: none;
}
.complete-btn{
  color: #fff;
  background: #3674DD;
  box-shadow: 0px 2px 40px 2px rgba(65, 68, 78, 0.4);
  border-radius: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.complete-btn.disabled{
  color: #6D7282;
  background:#41444E;
}

@media screen and (max-width: 1536px) {
  .time-block {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width:640px) {
  .time-block {
    width: 90px;
    height: 90px;
  }
}
@media screen and (max-width:480px) {
  .time-block {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 500px) {
  .enter-btn {
    font-size: 24px;
  }
  .max-btn{
    font-size: 12px;
  }
}

.join-btn{
  background: #C6165C;
  box-shadow: 0px 2px 40px 2px rgba(65, 68, 78, 0.4);
  border-radius: 10px;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
}
.switchNetwork-btn {
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7950DD;
  text-decoration: underline;
}
.nulshock-text{
  font-family: 'Nulshock';
}

.barCompleted{
  background-color: #3674DD;
}
.barcontainer{
  /* background-color: red !important; */
}
.wrapper{
  border-radius: 10px;
}
.loading-part{
  margin-right: 3.8rem;
}