/*  =================== LeftSide Bar style ==================*/
.claim-btn {
    color: #fff;
    background: #3674DD;
    box-shadow: 0px 2px 40px 2px rgba(65, 68, 78, 0.4);
    border-radius: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 31px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.claim-btn-disabled{
    color: #6D7282;
    background: #41444E;
    box-shadow: 0px 2px 40px 2px rgba(65, 68, 78, 0.4);
    border-radius: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 31px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.claim-btn span {
    color: rgba(255, 255, 255, 0.44);
    font-size: 18px;
}

.bg_block {
    background: linear-gradient(rgba(105, 105, 105, 0.16), rgba(105, 105, 105, 0.16), #1E1E1E);
    border-radius: 20px;
}

.vesting-connect-btn {
    background: #ad0f5b;
    border-radius: 15px;
    font-style: normal;
    font-weight: 700;
    /*font-size: 20px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.vesting-switch-btn {
    background: rgba(157, 22, 77, 0.2);
    border-radius: 15px;
    border: 2px solid #ad0f5b;
    font-style: normal;
    font-weight: 700;
    /*font-size: 18px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #D11963;
}
.switchNetwork-btn1 {
    font-style: normal;
    font-size: 20px;
    line-height: 29px;
    align-items: center;
    text-align: center;
    color: #7950DD;
    text-decoration: none;
}
.polygon-icon{
    width: 4rem;
}

.time-block-vesting{
    background: #110516;
    border-radius: 11px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.text-vesting-timer{
    font-size: 10px;
}
