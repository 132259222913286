.mint-card {
    border-radius: 10px;
    position: relative;
    background: #1F1822;
}

.mint-card:hover {
    cursor: pointer;
}

.nft-part:hover {
    cursor: pointer;
}

.forge-btn {
    background: #AD0F5B;
    border-radius: 10px;
    font-style: normal;
    /*font-weight: 700;*/
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.forge-btn.disabled {
    background: #1F1822;
    color: #6D7282;
}

.mint-pass-cart{
    background-image: url("../../assets/mintpass-gif.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120% 100%;
}

.free-claim-cart{
    background-image: url("../../assets/freeclaim-gif.gif");
    background-size: 120% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.discount-pass-cart{
    background-image: url("../../assets/Discount_Pass.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120% 100%;
}

.sticker-cart{
    /*background-size: 110% 100%;*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
}

.time-block-forge{
    background: #1F1822;
    border-radius: 11px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media screen and (max-width:640px) {
    .time-block-forge{
        width: 70px;
        height: 70px;
    }
}
@media screen and (max-width:480px) {
    .time-block-forge{
        width: 60px;
        height: 60px;
    }
}


@media only screen and (min-width: 1536px) {
    .sticker-cart-asset-1{
        /*background-size: 85% 100%;*/
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 164px;
        height: 160px;
    }
    .sticker-cart-asset-2{
        /*background-size: 100% 100%;*/
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 164px;
        height: 188px;
    }
}

@media only screen and (max-width: 1536px) {
    .sticker-cart-asset-1{
        background-size: contain;

        /*background-size: 85% 103%;*/
        background-position: center;
        background-repeat: no-repeat;
        width: 116px;
        height: 116px;
    }
    .sticker-cart-asset-2{
        background-size: contain;
        /*background-size: 100% 103%;*/
        background-position: center;
        background-repeat: no-repeat;
        width: 116px;
        height: 126px;
    }
}

/*@media (min-width: 451px) and (max-width: 1200px) {*/
/*    .sticker-cart-asset-1{*/
/*        background-size: 100% 100%;*/
/*        background-position: center;*/
/*        background-repeat: no-repeat;*/
/*    }*/
/*    .sticker-cart-asset-2{*/
/*        background-size: 115% 100%;*/
/*        background-position: center;*/
/*        background-repeat: no-repeat;*/
/*    }*/
/*}*/

/*@media only screen and (max-width: 450px) {*/
/*    .sticker-cart-asset-1{*/
/*        background-size: 100% 100%;*/
/*        background-position: center;*/
/*        background-repeat: no-repeat;*/
/*    }*/
/*    .sticker-cart-asset-2{*/
/*        background-size: 110% 100%;*/
/*        background-position: center;*/
/*        background-repeat: no-repeat;*/
/*    }*/
/*}*/


.disabled-cart{
    color: #767575;
}

#mc_embed_signup{
    background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;
}