@keyframes skeleton-loading {
    0% {
        background-color: #2D2730;
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.drops-left, .drops-right-top {
    background: #1F1822;
}

.first-edition-box {
    background-image: url("../../assets/first-edition-gif.gif");
    background-repeat: no-repeat;
    background-size: 140% 100%;
    background-position: center;
    height: 31rem;
    width: 100%;
    position: relative;
    border-radius: 15px;
}

.first-edition-box-1 {
    background-image: url("../../assets/first-edition-gif.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 27rem;
    width: 100%;
    position: relative;
    border-radius: 15px;
}

.h-connected {
    height: 27rem;
}

.h-disconnected {
    height: 30rem;
}

.time-block-drop{
    background: #110516;
    border-radius: 11px;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media (min-width: 768px) and (max-width: 1536px) {
    .first-edition-box-1 {
        height: 20rem;
    }
    .first-edition-box {
        height: 23rem;
    }
    .h-connected {
        height: 20rem;
    }

    .h-disconnected {
        height: 23rem;
    }

    .purchase-btn, .btn-disabled, .sold-out-btn{
        border-radius: 15px!important;
    }

    .time-block-drop{
        width: 80px;
        height: 80px;
    }

}


.max-w-left {
    max-width: 400px;
}

.min-w-left {
    min-width: 380px;
}


.text-orange {
    color: #FAFF00
}

.text-red {
    color: #FF0000;
}

.text-blue {
    color: #2081E2;
}

.text-purple {
    color: #AD00FF;
}

.text-pink {
    color: #AD0F5B;
}

.text-navy {
    color: #FF8A00;
}

.box-title {
    position: absolute;
    bottom: 3%;
    right: 5%;
}

.clipped-card {
    position: absolute;
    bottom: -7%;
    left: 0;
    padding: 5px 5px 5px 0;
    background: #1F1822;
    width: 30%;
    border-radius: 0 8px;
}

.clipped-card img {
    border-radius: 0 8px;
}

.purchase-btn {
    background: #3555E3;
    border-radius: 20px;
    font-style: normal;
    /*font-weight: 700;*/
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.view-item-btn {
    background: #3555E3;
    border-radius: 15px;
    font-style: normal;
    /*font-weight: 700;*/
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.use-btn {
    background: #3555E3;
    border-radius: 12px;
    font-style: normal;
    /*font-weight: 700;*/
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.sold-out-btn {
    background: #E33535;
    border-radius: 20px;
    font-style: normal;
    /*font-weight: 700;*/
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.btn-disabled {
    color: #6D7282;
    background: #41444E;
    box-shadow: 0px 2px 40px 2px rgba(65, 68, 78, 0.4);
    border-radius: 20px;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

.waiting-btn {
    color: #79757B;
    background: #36383F;
    border-radius: 8px;
    font-style: normal;
    font-size: 14px;
}

.bg-blue {
    color: #ffffff !important;
    background: #3555E3 !important;
}

.approve-usdc-btn {
    background: #35B3E3;
    border-radius: 20px;
    font-style: normal;
    /*font-weight: 700;*/
    font-size: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.approve-pass-btn {
    background: #F68E2E;
    border-radius: 20px;
    font-style: normal;
    /*font-weight: 700;*/
    font-size: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.nft-amounts {
    background: #2D2730;
    border-radius: 8px;
    padding: 4px 4px;
}

.original-nft-price {
    text-decoration: line-through;
}

.discounted-nft-price {

}

/*.drops-loading {*/
/*    height: 60vh;*/
/*}*/

.nft-card {
    border-radius: 10px;
    position: relative;
    background: #1F1822;
    display: block;
    width: 190px;
    height: 260px;
}

@media screen and (max-width: 1536px) {
    .nft-card {
        width: 140px;
        height: 181px;
    }
}

.selected-card {
    border: solid 1px #3555E3;
}


.nft-card img {
    border-radius: 10px;
    margin-bottom: 1em;
}

.nft-card:hover {
    cursor: pointer;
}

.pass-amount {
    position: absolute;
    left: 0;
    border-radius: 10px 0;
    background: #1F1822;
    padding: 10px 15px;
    top: 0;
}

.secondary-link {
    text-decoration: underline;
}

.secondary-link img {
    width: 20px;
    height: 20px;
    margin-top: 5px;
}

@media only screen and (max-width: 600px) {
    .pass-amount {
        padding: 6px 8px;
        border-radius: 10px 0 6px 0;
    }
    .time-block-drop{
        width: 57px;
        height: 57px;
    }
}

.bg-skeleton {
    background: #2D2730;
}

.bg-modal {
    background: #1F1822;
}

.bg-blur {
    filter: blur(8px);
}


.animation-custom {
    animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: .3;
    }
}

@media only screen and (min-width: 1536px) {
    .opensea-btn {
        background: #2081E2;
        border-radius: 15px;
        font-style: normal;
        /*font-weight: 700;*/
        font-size: 20px;
        display: flex;
        align-items: center;
        vertical-align: center;
        justify-content: center;
        text-align: center;
    }

    .opensea-btn img {
        width: 32px;
        height: 37px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .asset-card-3-1 {
        background: url("../../assets/first-edition-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 164px;
        height: 155px;
    }

    .asset-card-3-2 {
        background: url("../../assets/first-edition-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 164px;
        height: 188px;
    }

    .asset-card-1-1 {
        background: url("../../assets/mintpass-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 164px;
        height: 155px;
    }

    .asset-card-1-2 {
        background: url("../../assets/mintpass-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 164px;
        height: 188px;
    }

    .asset-card-2-1 {
        background: url("../../assets/freeclaim-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 164px;
        height: 155px;
    }

    .asset-card-2-2 {
        background: url("../../assets/freeclaim-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 164px;
        height: 188px;
    }
}
@media only screen and (max-width: 1536px) {
    .opensea-btn {
        background: #2081E2;
        border-radius: 10px;
        font-style: normal;
        /*font-weight: 700;*/
        font-size: 20px;
        display: flex;
        align-items: center;
        vertical-align: center;
        justify-content: center;
        text-align: center;
    }

    .opensea-btn img {
        width: 27px;
        height: 30px;
        margin-top: auto;
        margin-bottom: auto;
    }
    .asset-card-3-1 {
        background: url("../../assets/first-edition-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 116px;
        height: 116px;
    }

    .asset-card-3-2 {
        background: url("../../assets/first-edition-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 116px;
        height: 126px;
    }

    .asset-card-1-1 {
        background: url("../../assets/mintpass-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 116px;
        height: 116px;
    }

    .asset-card-1-2 {
        background: url("../../assets/mintpass-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 116px;
        height: 126px;
    }

    .asset-card-2-1 {
        background: url("../../assets/freeclaim-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 116px;
        height: 116px;
    }

    .asset-card-2-2 {
        background: url("../../assets/freeclaim-gif.gif");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 116px;
        height: 126px;
    }
}


@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.animate-spin {
    width: 30px;
    height: 30px;
    border: 3px solid #443E46;
    border-top: 3px solid #32BA7C;
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    left: 0;
}

.animate-spin-1 {
    left: 0;
    position: absolute;
    width: 30px;
    height: 30px;
    border: 3px solid #443E46;
    border-radius: 50%;
}

.animate-spin-2 {
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    background: #32BA7C;
    border-radius: 50%;
}

.loading-icon {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
}

.loading-number {
    padding-top: 4px;
    position: absolute;
    left: 11px;
}

.loading-check {
    position: absolute;
    padding-top: 7px;
    left: 6px;
}