@font-face {
  font-family: 'Nulshock';
  src: local('Nulshock'), url(./nulshock+bd.ttf) format('truetype');
}
body {
  margin: 0;
  background-color: rgb(18, 9, 21);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* overall Scrollbar css */

::-webkit-scrollbar {
  width: 6px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #AD0F5B;
  border-radius: 50vh;
  /* border: 3px solid #edf2f7; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #AD0F5B;
}