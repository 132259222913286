.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Toast css*/

.Toastify__toast.Toastify__toast-theme--light{
  background:#1B141F!important;
  color: #fff!important;
  border: solid 1px #3E3842;

}

.Toastify__toast--warning{

}
.Toastify__progress-bar--warning{
  background: #BFD24C!important;
}
.Toastify__toast--success{

}
.Toastify__progress-bar--success{
  background: #32BA7C!important;
}
.Toastify__toast--error{

}
.Toastify__progress-bar--error{
  background: #D24C4C!important;
}
.Toastify__toast--info{

}
.Toastify__progress-bar--info{
  background: #4C69D2!important;
}
.Toastify__close-button {
  color: #fff!important;
  font-weight: bold!important;
  opacity: 1!important;
  font-size: 15px!important;
  padding: 3px 3px!important;
}
.Toastify__close-button > svg{
  width: 20px!important;
  height: 20px!important;
}
.Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter svg{ display: none!important;}
.toast--success .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter{
  width: 22px!important;
  height: 22px;
  margin-bottom: 10px;
  background-image: url("./assets/notification-img/check-icon.png")!important;
}
.toast--info .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter{
  width: 22px!important;
  height: 22px;
  margin-bottom: 10px;
  background-image: url("./assets/notification-img/info-icon.png")!important;
}
.toast--warning .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter{
  width: 22px!important;
  height: 22px;
  margin-bottom: 10px;
  background-image: url("./assets/notification-img/warning-icon.png")!important;
}
.toast--error .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter{
  width: 22px!important;
  height: 22px;
  margin-bottom: 10px;
  background-image: url("./assets/notification-img/error-icon.png")!important;
}
@media screen and (min-width: 640px) {
  .Toastify__toast.Toastify__toast-theme--light{
    border-radius: 10px!important;
  }
}

.notification-title{
  color: #939393;
  font-weight: bold;
  width: 18em;
  white-space: break-spaces;
  display: block;

}
.notification-body{
  color: #fff;
  width: 18em;
  display: block;
  /*white-space: break-spaces;*/
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}